/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "lazysizes";
import "lazysizes/plugins/blur-up/ls.blur-up";
import "objectFitPolyfill";
import SmoothScroll from 'smooth-scroll';
import { ThemeProvider } from "./src/context/ThemeContext";
import "./src/css/fontello/css/fontello.css";
import "./src/css/global.css";

// Polyfills closest() property for legacy browsers
if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i,
      el = this
    do {
      i = matches.length
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement))
    return el
  }
}

export const onRouteUpdate = () => {
  // TODO: Improve smooth scrolling
  // eslint-disable-next-line no-unused-vars
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    topOnEmptyHash: true,
    offset: function (anchor, toggle) {
      const headerHeight = document.querySelector('.js-sticky-header').clientHeight;
      // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
      // This example is a function, but you could do something as simple as `offset: 25`
      // An example returning different values based on whether the clicked link was in the header nav or not
      if (toggle.closest('.c-scrollspy') && toggle.getAttribute('href') !== "#cards") {
        if (toggle.getAttribute('href') === "#benefits") {
          return 146 + 46;  // Masthead + On Page Nav + Section Padding
        } else if (toggle.closest('.c-scrollspy') && toggle.getAttribute('data-offset') === "industry")  {
          return 146;  // Masthead
        } else if (toggle.closest('.c-scrollspy') && toggle.getAttribute('href') === "solutions")  {
          return 146 + 46;  // Masthead + On Page Nav
        } else {
          return 146 + 46 + 48;  // Masthead + On Page Nav + Section Padding
        }
      } else if (toggle.closest('.c-scrollspy') && toggle.getAttribute('href') === "#cards")  {
        return 146 + 48; // Masthead + Large Section Padding
      } else {
        return headerHeight; // Scroll all the way to the #main anchor with no offset
      }
    },
    //speedAsDuration: true
});
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill to play videos when scrolled in view (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`) // eslint-disable-line
  }

  // Polyfill for Smooth Scrolling
  if(!("scrollBehavior" in document.documentElement.style)) {
    import("scroll-behavior-polyfill");
  }
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
