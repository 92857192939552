import React, { useEffect, useState, startTransition } from "react"

// make a new context
const ThemeContext = React.createContext({
  headerHeight: 0,
  setHeaderHeight: (number) => {},
  hydrated: false,
})

// create the provider
const ThemeProvider = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => {
    startTransition(() => {
      setHydrated(true)
    })
  }, [])

  return (
    <ThemeContext.Provider
      value={{
        setHeaderHeight: (value) => setHeaderHeight(value),
        headerHeight: headerHeight,
        hydrated: hydrated,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider }
